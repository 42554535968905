import { ISwiperData, SwiperImageExtension } from '../../shared/interfaces/swiper.interfaces';

const carouselImagePathPrefix = 'assets/images/home-carousel';

const currentFormat = SwiperImageExtension.PNG;

export const CarouselElements: ISwiperData[] = [
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-575-alatt${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-576-767${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-768-959${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-960-1199${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-1/NFTCuzlet-ASIAC-202311-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/mobil-szolgaltatas',
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A55-5G-202408-575-alatt${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A55-5G-202408-576-767${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A55-5G-202408-768-959${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A55-5G-202408-960-1199${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-2/Slider-Samsung-Galaxy-A55-5G-202408-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/keszulekek/Samsung%20Galaxy%20A55%205G',
      queryParams: {
        szolgaltatas: 110083345,
        husegIdo: 24,
        futamIdo: 0,
        szin: 0,
      },
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-Xiaomi-Redmi-A3-202408-575-alatt${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-Xiaomi-Redmi-A3-202408-576-767${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-Xiaomi-Redmi-A3-202408-768-959${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-Xiaomi-Redmi-A3-202408-960-1199${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-3/Slider-Xiaomi-Redmi-A3-202408-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/keszulekek/Xiaomi%20Redmi%20A3',
      queryParams: {
        szolgaltatas: 110083345,
        husegIdo: 24,
        futamIdo: 0,
        szin: 0,
      },
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-Kezdasulit-50GB-202409-575-alatt${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-Kezdasulit-50GB-202409-576-767${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-Kezdasulit-50GB-202409-768-959${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-Kezdasulit-50GB-202409-960-1199${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-4/Slider-Kezdasulit-50GB-202409-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/mobil-szolgaltatas',
    },
  },
  {
    small: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-Kezdasulit-100GB-202409-575-alatt${currentFormat}`,
    },
    smallMedium: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-Kezdasulit-100GB-202409-576-767${currentFormat}`,
    },
    medium: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-Kezdasulit-100GB-202409-768-959${currentFormat}`,
    },
    mediumLarge: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-Kezdasulit-100GB-202409-960-1199${currentFormat}`,
    },
    large: {
      image: `${carouselImagePathPrefix}/slide-5/Slider-Kezdasulit-100GB-202409-1200-1920${currentFormat}`,
    },
    redirectPath: {
      url: '/mobil-szolgaltatas',
    },
  },
];
